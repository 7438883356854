@import '../components/functions';
@import '../components/globals/variables';

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "node_modules/bootstrap/scss/functions";

$enable-cssgrid: true !default;

// 3. Include remainder of required Bootstrap stylesheets
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";


// Layout & components
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
// @import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
// @import "node_modules/bootstrap/scss/tables";
// @import "node_modules/bootstrap/scss/forms";
// @import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
// @import "node_modules/bootstrap/scss/dropdown";
// @import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/nav";
// @import "node_modules/bootstrap/scss/navbar";
// @import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/accordion";
// @import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/list-group";
// @import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/carousel";
// @import "node_modules/bootstrap/scss/spinners";
// @import "node_modules/bootstrap/scss/offcanvas";

// Helpers
// @import "node_modules/bootstrap/scss/helpers";

// Utilities
@import "node_modules/bootstrap/scss/utilities/api";

.container {
    padding-right: tr(16);
    padding-left: tr(16);
}

// Customize
@media (min-width: 1284px) {
    .container {
        max-width: 1248px !important;
    }
}

// @media (max-width: 767.98px) {
//     .grid {
//         gap: tr(12);
//     }
// }

.alert {
    color: white;
    padding: tr(8) tr(32) tr(8) tr(12);
    border-radius: $rounded-small;

    button {
        position: absolute;
        top: calc(50% - tr(2));
        right: tr(16);
        border: none;
        background-color: transparent;
        transform: rotate(45deg) translateY(-50%);
        width: tr(32);
        height: tr(32);
        
        i {
            font-size: tr(18);
            color: white;
        }
    }
}

.alert-primary {
    border-color: $color-accent;
    background-color: rgba($color-accent, .8);
}

.alert-danger {
    border-color: $color-error;
    background-color: rgba($color-error, .8);
}

.alert-warning {
    border-color: $color-warning;
    background-color: rgba($color-warning, .8);
}

.alert-info {
    border-color: $color-info;
    background-color: rgba($color-info, .8);
}

.alert-success {
    border-color: $color-success;
    background-color: rgba($color-success, .8);
}

// .row {
//     margin-right: tr(-12);
//     margin-left: tr(-12);

//     > .col,
//     > [class*="col-"] {
//         padding-right: tr(12);
//         padding-left: tr(12);
//     }
// }

.nav-pills-wrapper {
    overflow: auto;
    margin-bottom: tr(52);
}

.nav-pills {
    justify-content: center;
    min-width: max-content;

    .nav-item {
        margin: 0 tr(8);

        .nav-link {
            background-color: $color-gray-light;
            padding: tr(4) tr(24);
            border-radius: $rounded-small;
            transition: all .2s ease-in-out;
            color: $color-base2;

            &:hover {
                background-color: rgba($color-secondary, .1);
            }
    
            &.active {
                background-color: $color-secondary;
                color: white;
            }
        }
    }
    
}