// ##########################################################################
// ######## FONTS

$font-source: 'https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;600&family=Nunito:wght@400;700&display=swap';
$font-family: 'Nunito', sans-serif;
$font-family2: 'Baloo 2', cursive;
$font-base: 16px;
$font-body: tr(16); // body text
$fw-normal: 400; // font-weight: normal
$fw-medium: 500; // font-weight: bold
$fw-bold: 600; // font-weight: bold
$lh-base: 1.5; // line-height

// ##########################################################################
// ######## COLORS
// Theme Colors
$color-bg: white;
$color-bg2: #F2FAF4;

$color-base: #28292B;
$color-base2: #636363;

$color-primary: #4081F1;
$color-secondary: #AFCD21;
$color-tertiary: #91c03f;

$color-accent: #389ED9;
$color-accent-light: #F3FCF8;

$color-gray: #D0D0D0;
$color-gray-light: #EDF4F8;
$color-gray-lightest: #f7f8f8;

$color-gr-bg: linear-gradient(90deg, #389ED9 24.18%, #B1CF1F 150.94%);
$color-gr-bbg: linear-gradient(90deg, #389ED9 1.35%, #B1CF1F 100%);

// Semantic Colors
$color-success: #00C48C;
$color-info: #0084F4;
$color-warning: #FFA722;
$color-error: #FF3C5A;

// Other Colors
$color-fb: #4167b1;
$color-wa: #25D366;
$color-tw: #55ACEE;

// ##########################################################################
// ######## MISCELLANEOUS
$nav-height: tr(64);
$nav-height-sm: tr(56);

$shadow: 0 .375rem tr(20) rgba(79, 104, 114, 0.08);
$shadow-blue: 0 tr(16) tr(24) tr(-24) rgba(6, 58, 78, 0.2);
$shadow-gray: 0px 6px 32px rgba(79, 104, 114, 0.08);
$shadow0: 0 tr(2) .375rem 0 rgba(128, 128, 136, 0);
$shadow10: 0 tr(2) .375rem 0 rgba(128, 128, 136, 0.1);
$shadow20: 0 tr(2) .375rem 0 rgba(128, 128, 136, 0.2);
$shadow40: 0 tr(2) .375rem 0 rgba(128, 128, 136, 0.4);
$shadow60: 0 tr(2) .375rem 0 rgba(128, 128, 136, 0.6);

$border-thin: .0625rem solid #f2f2f3;
$border-regular: .0625rem solid #ababaf;
$border-red: .0625rem solid $color-error;
$border-green: .0625rem solid $color-success;

$rounded-small: tr(4);
$rounded-medium: tr(6);
$rounded-large: tr(8);
$rounded-xlarge: tr(16);
$rounded-xxlarge: tr(24);
$rounded-full: tr(100);
$rounded-circle: 50%;
$egs: .5rem;
$cbzr: cubic-bezier(.59,.17,0,1.58);